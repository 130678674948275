var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title: "Without directive",
        subtitle:
          "You can copy text without a specific button. Use $copyText to copy text when event got fired.",
        "code-toggler": "",
      },
    },
    [
      _c("vs-input", {
        staticClass: "inline-flex mb-2 mr-2",
        model: {
          value: _vm.text2,
          callback: function ($$v) {
            _vm.text2 = $$v
          },
          expression: "text2",
        },
      }),
      _c("vs-button", { on: { click: _vm.copyText } }, [_vm._v("Copy")]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n    <vs-input v-model=\"text2\" class=\"inline-flex\" />\n    <vs-button @click=\"copyText\">Copy</vs-button>\n</template>\n\n<script>\nexport default {\n    data() {\n        return {\n            text2: 'Copy Me Without Directive',\n        }\n    },\n    methods: {\n        copyText() {\n            const thisIns = this;\n            this.$copyText(this.text2).then(function() {\n                thisIns.$vs.notify({\n                    title: 'Success',\n                    text: 'Text copied successfully',\n                    color: 'success',\n                    iconPack: 'feather',\n                    position: 'top-center',\n                    icon: 'icon-check-circle'\n                })\n            }, function() {\n                thisIns.$vs.notify({\n                    title: 'Failed',\n                    text: 'Error in copying text',\n                    color: 'danger',\n                    iconPack: 'feather',\n                    position: 'top-center',\n                    icon: 'icon-alert-circle'\n                })\n            })\n        }\n    }\n}\n</script>\n    "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title: "Using Directive",
        subtitle: "Use directive on button to copy text",
        "code-toggler": "",
      },
    },
    [
      _c("vs-input", {
        staticClass: "inline-flex mb-2 mr-2",
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
      _c(
        "vs-button",
        {
          directives: [
            {
              name: "clipboard",
              rawName: "v-clipboard:copy",
              value: _vm.text,
              expression: "text",
              arg: "copy",
            },
            {
              name: "clipboard",
              rawName: "v-clipboard:success",
              value: _vm.onCopy,
              expression: "onCopy",
              arg: "success",
            },
            {
              name: "clipboard",
              rawName: "v-clipboard:error",
              value: _vm.onError,
              expression: "onError",
              arg: "error",
            },
          ],
        },
        [_vm._v("\n      Copy\n    ")]
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n    <vs-input v-model=\"text\" class=\"inline-flex\" />\n    <vs-button\n        v-clipboard:copy=\"text\"\n        v-clipboard:success=\"onCopy\"\n        v-clipboard:error=\"onError\">\n        Copy\n    </vs-button>\n</template>\n\n<script>\nexport default {\n    data() {\n        return {\n            text: 'Copy Me!',\n        }\n    },\n    methods: {\n        onCopy() {\n            this.$vs.notify({\n                title: 'Success',\n                text: 'Text copied successfully',\n                color: 'success',\n                iconPack: 'feather',\n                position: 'top-center',\n                icon: 'icon-check-circle'\n            })\n        },\n        onError() {\n            this.$vs.notify({\n                title: 'Failed',\n                text: 'Error in copying text',\n                color: 'danger',\n                iconPack: 'feather',\n                position: 'top-center',\n                icon: 'icon-alert-circle'\n            })\n        },\n    }\n}\n</script>\n    "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }